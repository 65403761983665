<template>
    <div>
        <b-modal v-model="modalSuccess" title="Cadastro enviado com sucesso!">
          <div class="alert alert-success" role="alert">Cadastro enviado com sucesso!</div>
        </b-modal>
        <b-modal id="modal-form" v-model="modalCadastro">
          <div class="bg-wrapper">
            <div class="background-r">
              <div class="forms">
                <div class="register">
                  <b-form ref="formRegister" @submit.stop.prevent="submitRegister">
                    <b-row class="d-flex justify-content-between">
                      <div class="col-6"><h1 :class="(!formSwitch ? 'selected' : '') + ' h1Form'" @click="switchToSenders">Solicitar corrida</h1></div>
                      <div class="col-6"><h1 :class="(formSwitch ? 'selected' : '') + ' h1Form'" @click="switchToDeliveries">Fazer entrega</h1></div>
                    </b-row>
                    <b-row>
                      <b-col
                        v-for="field in (!formSwitch ? fieldsSender : fieldsDeliveries)"
                        :key="field.key"
                        :cols="field.col"
                      >
                        <!-- Normal fields -->
                        <b-form-group
                          v-if="field.type !== 'view'"
                          :id="'input-group-' + field.key"
                          :label="field.label + field.required"
                          :label-for="'input-' + field.key"
                        >
                          <b-form-input
                            v-if="
                              ['text', 'number', 'email', 'password'].includes(
                                field.type
                              )
                            "
                            :id="'input-' + field.key"
                            v-model="(!formSwitch ? formSender : formDeliveries)[field.key]"
                            :type="
                              field.type === 'password'
                                ? eye
                                  ? 'password'
                                  : 'text'
                                : field.type
                            "
                            :min="field.min"
                            v-maska="field.mask"
                            :class="field.type === 'password' ? 'input-eye' : ''"
                            :placeholder="field.placeholder || ''"
                            :required="field.isRequired"
                          ></b-form-input>

                          <i
                            v-if="field.type === 'password'"
                            @click="eye = !eye"
                            class="eye material-icons-outlined"
                            >{{ eye ? "visibility" : "visibility_off" }}</i
                          >

                          <b-form-select
                            v-if="field.type === 'select'"
                            v-model="(!formSwitch ? formSender : formDeliveries)[field.key]"
                            :options="field.options"
                            required
                          >
                            <template #first>
                              <b-form-select-option :value="undefined" disabled
                                >-- Selecione um
                                {{ field.label }} --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="!loading">
                      <button style="margin: 8px auto;" type="submit">Fazer pré-cadastro</button>
                    </b-row>
                    <b-row v-else class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Carregando...</span>
                      </div>
                    </b-row>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <Topbar btnLabel="Pré cadastro" @button="openModalCadastro"></Topbar>
        <!-- banner -->
        <div class="background">
          <div class="hero" id="banner" style="padding: 0; gap: 0">
            <div class="plano-title">
              <h1>Vem com a <b class="color-primary-variant">Vulpee</b> agilizar suas entregas</h1>
              <p>
              Conte com um app rápido e intuitivo para solicitar um entregador.
             <b>Conte com preços justos e transparentes.</b>
            </p>
            <div class="btn-wrapper">
              <button @click="openModalCadastro" class="btn btn-download text-uppercase">Pré cadastro</button>
              <button class="btn btn-demo text-uppercase text-white"><i class="icon-cam mr-2"></i>Ver Demonstração</button>
            </div>
          </div>
            <img class="rectangle-mobile" src="../assets/images/splash-screen.svg" />
          </div>
        </div>
        <!-- end banner -->
        <!-- logo section -->
        <section id="brands" class="brand-margin">
          <h3 class="title-brands">Apoiadores</h3>
            <div class="logo-brands">
              <img src="../assets/images/SHINIER.svg" alt="SHINIER">
            </div>
          </section>
         <!-- end logo section -->

         <!-- section sobre -->
        <section id="corrida" class="hero">
        <!-- <div class="v-center v-center-c"> -->
          <div class="as-center">
            <h2 class="h2-section">Vantagens das entregas</h2>

            <p class="p-section">Economize com suas entregas locais, sem diárias e com tarifas abaixo do mercado.</p>
            <div class="feat-list" v-for="(card, index) in cardFeatures" :key="index">
              <div class="title-feat-wrapper">
                <img class="img-feat" :src="card.img" alt="" />
                <div class="d-flex flex-column">
                  <h3>{{card.title}}</h3>
                  <p>{{card.text}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <picture>
                  <img src="../assets/images/posso-entregar.svg" alt="Imagem das principais telas do aplicativo da Small Doações" style="width: 60%; height: 60%;">
              </picture>
          </div>
        <!-- </div> -->
        </section>
        <!-- end section sobre -->

        <!-- section como funciona -->
        <section id="como-funciona" class="hero" style="background-color:rgba(255, 165, 59, 0.2);">
          <div class="as-center">
            <h2 class="h2-section">Pode contar com a gente.</h2>
            <h2 class="h2-section" style="color: #422B70">Quem já confia na Vulpee!</h2>
            <p style="text-align: left;">Mais de 30.000 Entregas realizadas com sucesso!!!</p>
          </div>
          <div class="">
              <picture>
                  <source srcset="../assets/images/Image.svg" type="image/jpg" height="100%" width="100%">
                  <source srcset="../assets/images/Image.svg" type="image/webp" heigth="100%" width="100%">
                  <img src="../assets/images/Image.svg" alt="Imagem das principais telas do aplicativo da Small Doações" style="width: 345px;height: 240px;">
              </picture>
          </div>
        </section>
        <!-- end como fuciona -->
        <section class="hero" id="entrega" :style="windowSize < 992 ? 'flex-direction: column-reverse;' : null">
          <div class="">
              <picture>
                <source srcset="../assets/images/app-features.svg" type="image/jpg" height="100%" width="100%">
                  <source srcset="../assets/images/app-features.svg" type="image/webp" heigth="100%" width="100%">
                  <img src="../assets/images/app-features.svg" alt="Imagem das principais telas do aplicativo da Small Doações" style="width: 60%; height: 60%;">
              </picture>
          </div>
          <div class="as-center">
            <h4 class="h2-section">Fazer <span style="color: #422B70">Entrega</span></h4>
            <p class="p-section">Tenha a liberdade de entregar quando quiser e aumentar seus ganhos. Receba os repasses semanalmente. Aceite apenas os pedidos que quiser, sem penalidades.</p>
            <div class="feat-list" v-for="(card, index) in cardFeaturePosso" :key="index">
              <div class="title-feat-wrapper">
                <img class="img-feat" :src="card.img" alt="" />
                <div class="d-flex flex-column">
                  <h3>{{card.title}}</h3>
                  <p>{{card.text}}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="hero" id="preciso">
          <div class="as-center">
            <h4 class="h2-section">Solicitar <span style="color: #FFA53B">Corrida</span></h4>
            <p class="p-section">Sem contratos, sem diárias e sem letrinhas miúdas para te enganar. Apenas R$2,00 reais por solicitação e o valor da corrida conforme tabela pré-fixada, sem surpresas!</p>
            <div class="feat-list" v-for="(card, index) in cardFeaturePreciso" :key="index">
              <div class="title-feat-wrapper">
                <img class="img-feat" :src="card.img" alt="" />
                <div class="d-flex flex-column">
                  <h3>{{card.title}}</h3>
                  <p>{{card.text}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="">
              <picture>
                  <img src="../assets/images/preciso-entregar.svg" alt="Imagem das principais telas do aplicativo da Small Doações" style="width: 60%; height: 60%;">
              </picture>
          </div>
        </section>
        <section class="hero" id="preciso">
          <div class="as-center">
            <h4 class="h2-section text-center">Bora começar?</h4>
            <p class="text-center">Pra que deixar pra amanhã, o negócio que pode mudar sua vida hoje?</p>
            <b-container class="container-center">
              <b-row>
                <b-col style="padding: 1%;">
                  <p>Para usuários IoS</p>
                  <img class="btn-store" src="../assets/images/appstore.svg" alt="">
                </b-col>
                <b-col style="padding: 1%;">
                  <p>Para usuários Android</p>
                  <img class="btn-store" src="../assets/images/googleplay.svg" alt="">
                </b-col>
                <b-col style="padding: 1%;">
                  <p>Para o desktop</p>
                  <button @click="openModalCadastro" class="btn btn-download-2 text-uppercase">Download App</button>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </section>

        <section class="hero" id="comunidade">
          <div>
            <h4 class="h2-section">Um pouco sobre nós...</h4>
            <p class="p-section">Em tão pouco tempo, começando com um grupo no whatsapp, ajudei milhares de parceiros a atingir resultados incrivéis. Gerar novas oportunidades de vendas. E mudar a vida das pessoas faz meu coração bater mais forte.</p>
            <div class="d-flex feat" :style="windowSize < 1260?  'left: 9%': null">
            <div class="feat-list">
              <div class="btn-wrapper">
              <button @click="openModalCadastro" class="btn btn-download text-uppercase">Pré cadastro</button>
              <button class="btn btn-demo text-uppercase text-white"><i class="icon-cam mr-2"></i>Ver Demonstração</button>
            </div>
            </div>
          </div>
          </div>
          <div class="" :style="windowSize <= 768 ? 'margin-top: 30%': null">
              <picture>
                  <img :style="windowSize < 768 ? 'margin-top: 40%;': null" src="../assets/images/metrics.svg" alt="Imagem das principais telas do aplicativo da Small Doações" heigth="100%" width="100%">
              </picture>
          </div>
        </section>

        <section class="hero" id="preciso" style="padding: 0; gap: 0;">
          <div :style="windowSize > 768 ?'margin-left: 10%;': 'margin-left: 0px; padding: 5%;'">
            <h4 class="h2-section">Benefícios e Resultados</h4>
            <p class="p-section" style="width: auto;">Ficamos muito felizes com o crescimento de nossos parceiros motoristas a cada dia. Eles fazem em média:</p>
            <div class="d-flex feat">
              <div class="feat-list" v-for="(card, index) in cardPlans" :key="index">
              <div class="title-feat-wrapper feat-plans">
                <img class="img-feat" :src="card.img" alt="" />
                <h3 style="color: #000; font-weight: 900;">{{card.title}}
                  <br>
                  <span style="color: #293340" class="text-uppercase text-sm-center span-plan">{{card.text}}</span></h3>
              </div>
            </div>
            </div>
          </div>
          <div class="" :style="windowSize <= 768 ? 'margin-top: 50%': null">
              <picture>
                  <img src="../assets/images/plans-for-users (2).svg" alt="Imagem das principais telas do aplicativo da Small Doações" style="width: 60%; height: 60%;">
              </picture>
          </div>
        </section>
        <section class="hero2" style="background-color: #422B70; padding: 5%;">
        <div>
            <!-- <div ">-->
            <div class="section-purple">
              <h1 class="media-storie">Histórias de mudança de vida nos motivam</h1>
              <carousel :perPage="1" :autoplayTimeout="3000" autoplay loop>
                <slide>
                <p class="quotes" style="color: #fff; text-align: left;">Me salvou em varios momentos em que meu motoboy não pode fazer a entrega por ter furado um pneu ou por ter muitas entregas ao mesmo tempo. Um excelente app que me ajudou a fazer muito mais entregas com menos custo.</p>
                <b-media>
                  <template #aside>
                    <img src="../assets/images/user 9.svg" alt="Media Aside">
                  </template>
                  <h2 style="color: #fff; text-align: left;">Marlon</h2>
                  <h2 style="text-align: left;">⭐⭐⭐⭐⭐</h2>
                </b-media>
                </slide>
                <slide>
                <p class="quotes" style="color: #fff; text-align: left;">Eu sempre tive problemas com motoboys, ficavam uma semana e ja partiam pra outro lugar, nunca consegui firmar um na minha hamburgueria. Esse app me ajudou muito porque sempre que eu tinha uma entrega era só apertar um botão e vinha alguem fazer a entrega, sem custo fixo, sem me preocupar se amanhã eu teria motoboy a disposição.</p>
                <b-media>
                  <template #aside>
                    <img src="../assets/images/user 9.svg" alt="Media Aside">
                  </template>
                  <h2 style="color: #fff; text-align: left;">Carlos Alberto</h2>
                  <h2 style="text-align: left;">⭐⭐⭐⭐⭐</h2>
                </b-media>
              </slide>
                <slide>
                <p class="quotes" style="color: #fff; text-align: left;">Comecei a fazer entregas recentemente e não tinha conseguido um lugar fixo ainda, esse app me fez ter mais entregas do que se eu ficasse em um lugar só. Eu ganho o valor da corrida cheio sem nem um desconto. Nem quero mais ficar fixo em um lugar só.</p>
                <b-media>
                  <template #aside>
                    <img src="../assets/images/user 9.svg" alt="Media Aside">
                  </template>
                  <h2 style="color: #fff; text-align: left;">Ney</h2>
                  <h2 style="text-align: left;">⭐⭐⭐⭐⭐</h2>
                </b-media>
                </slide>
                <slide>
                <p class="quotes" style="color: #fff; text-align: left;">Muito bom, recomendo a todos os irmãos que tão parados em algum restaurante esperando entrega. Com a Vulpee não tem como ficar muito tempo parado. Eu ganho muito mais agora do que quando eu tinha fixo e ficava horas esperando sair uma entrega.</p>
                <b-media>
                  <template #aside>
                    <img src="../assets/images/user 9.svg" alt="Media Aside">
                  </template>
                  <h2 style="color: #fff; text-align: left;">Jair Alemão</h2>
                  <h2 style="text-align: left;">⭐⭐⭐⭐⭐</h2>
                </b-media>
                </slide>
              </carousel>
            </div>
          </div>
        </section>
        <div class="rect-absolute"></div>
        <section class="hero" id="duvidas" style="justify-content: flex-start;">
          <div>
              <h4 class="h2-section">FAQ</h4>
              <p class="p-section">Dúvidas Frequentes? Estamos sempre dispostos! Não encontrou? Chama nosso suporte <a href="https://web.whatsapp.com/send?phone=+554796720562">aqui: <img src="../assets/images/whats-icon.svg" alt="whatsapp icon" style="width: 5%"></a></p>
              <div @click="openAccordion('faq1')" class="feat-list" style="padding: 5% 5% 5% 0;text-align: left;">
                <div class="title-feat-wrapper">
                  <h5 :style="windowSize <= 768 ? 'width: 100vw; padding: 5%':'width: 100%'" class="acc-style">Que documentos preciso pra cadastrar no app?
                    <span class="material-icons-outlined">expand_more</span>
                  </h5>
                </div>
                <p id="faq1" class="faq">O primeiro cadastro é somente das suas informações de usuário, aí conforme seu perfil irá pedir o número de documentos de identificação apropriados. Após o cadastro acesse seu perfil e termine o cadastro fazendo upload dos documentos necessários, como CNH, MEI, CNPJ, RG.</p>
              </div>
              <div @click="openAccordion('faq2')" class="feat-list" style="padding: 5% 5% 5% 0;text-align: left;">
                <div class="title-feat-wrapper">
                  <h5 :style="windowSize <= 768 ? 'width: 100vw; padding: 5%':'width: 100%'" class="acc-style">Tenho que pagar alguma mensalidade?
                    <span class="material-icons-outlined">expand_more</span>
                  </h5>
                </div>
                <p id="faq2" class="faq">Não, para quem solicita a corrida cobramos uma taxa fixa de administração, e os motoristas recebem o valor calculado da corrida pela quilometragem total.</p>
              </div>
              <div @click="openAccordion('faq3')" class="feat-list" style="padding: 5% 5% 5% 0;text-align: left;">
                <div class="title-feat-wrapper">
                  <h5 :style="windowSize <= 768 ? 'width: 100vw; padding: 5%':'width: 100%'" class="acc-style">Quero pedir uma única corrida, posso usar o app?
                    <span class="material-icons-outlined">expand_more</span>
                  </h5>
                </div>
                <p id="faq3" class="faq">Sim, mesmo que você vai usar de forma esporádica nossos motoristas estão dispostos a fazer a corrida pra você.</p>
              </div>
              <div @click="openAccordion('faq4')" class="feat-list" style="padding: 5% 5% 5% 0;text-align: left;">
                <div class="title-feat-wrapper">
                  <h5 :style="windowSize <= 768 ? 'width: 100vw; padding: 5%':'width: 100%'" class="acc-style">Posso usar o App do Vulpee junto com outros apps?
                    <span class="material-icons-outlined">expand_more</span>
                  </h5>
                </div>
                <p id="faq4" class="faq">Sim, nossa plataforma não pede exclusividade, mas garanto que você não vai querer usar outra.</p>
              </div>
            </div>
        </section>

<Footer />
    </div>
</template>
<style scoped lang="stylus">

    @import '../style/colors.styl';
    @import '../style/mixins.styl';
    @import '../style/dimens.styl';

    .background
        gold-background()
        width: 100%
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        position: absolute;
        margin: 0;
        top: 0;
        justify-content: center;
        align-items: stretch;

        .plano-title
        .plano-text
            text-align left
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: flex-start;

        .plano-title h1
            color #fff
            margin-bottom 15px
            padding: 3%
            /* height: 192px; */
            left: 11.37%;
            right: 44.32%;
            top: calc(50% - 192px/2 - 101.22px);
            font-style: normal;
            font-weight: 900;
            font-size: 80px;
            line-height: 96px;
            /* or 120% */

            letter-spacing: -2.35294px;

            @media (max-width: lg)/*  and (max-width: xl) */
                padding: 0;
                height: auto;
                letter-spacing: normal;
                font-size: 64px
                text-align: center
                margin-top: 5%;

          .plano-title p
            padding: 3%;
            font-style: normal;
            width: 62.5%;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            margin: 0;
            color: rgba(255,255,255,0.595);

            & b
              color: rgba(255,255,255,1);

            @media (max-width: lg)
              margin: auto
              text-align: center
              font-size: 20px

        .plano-text
            color color-theme()
            margin-bottom 10px

      @media (max-width: lg)
        flex-direction: row

    .planos
        display flex
        justify-content space-between

        @media (max-width: lg)
            flex-direction column

        & > div
            background-color content-base
            padding 0 (10% / 3)

            @media (max-width: lg)
                margin 12px 0 !important

            &:nth-child(2n)
                margin 0 4%

            span
                display block

            .plano-card-title
                color primary
                font-size 1.5em
                margin 40px 0 10px

            .plano-card-text
                color info
                text-align justify
                font-size 1.2em
                border-bottom 1px solid primary
                padding 10px 0

            .plano-card-price
                color primary-variant
                font-size 2em
                margin-top 32px

            .plano-card-price-info
                color info

            .plano-btn
                width 50%
                min-width 140px
                padding 12px 16px
                margin 40px auto 30px
                display block
                border-radius: 32px
                color color-theme()
                background-color primary-variant
                cursor pointer
                border 1px solid primary-variant
                transition .3s ease-in-out

                &:hover
                    background-color: color-theme();
                    color: #313131;
                    border-color: #d2d2d8;

    .hero
        display: flex;
        position: relative;
        margin: 0 auto;
        top: 10%;
        // gap: 10%
        padding: 10%;
        align-items: center;
        align-content: center;
        // justify-content: center;
        flex-direction: row;

        @media (max-width: lg)
          flex-direction: column

    .hero2
        background-image: url("../assets/images/Group 2.svg")
        background-repeat no-repeat
        background-position: 90% 50%

    .feat-list
        display: flex;
        position: relative;
        margin: 0 auto;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;

        & p
          margin: 0;
          padding 0;
          width: 100%;
          text-align: left;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;

          @media (max-width: lg)
            text-align center
            padding 0 48px

          @media (max-width: md)
            padding 0 24px

      @media (max-width: lg) {
          align-items: center;
          align-content: center;
          width: 90%
      }

    .rectangle-mobile
        padding 7.5%
        width: calc(100% - 53vw);
        filter: drop-shadow(0px 30px 18px rgba(0, 0, 0, 0.61));
        @media (max-width: lg) {
          padding: 7.5%;
          width: 50%;
          filter: drop-shadow(0px 30px 18px rgba(0,0,0,0.61));
        }

    .btn-download
      background-color: primary-variant
      padding: 4%;
      width: 200px

    .btn-download-2
      background-color: primary
      padding: 4%;
      width: 200px
      color white

    .btn-demo
      padding: 4%;
      width: 300px

    .btn-wrapper {
        display: flex;
      /*  flex-direction: column;
        flex-wrap: wrap; */
        align-content: center;
        justify-content: center;
        padding: 3%;
        align-items: center;

        @media (max-width lg){
          margin: auto
          flex-direction: column
        }
    }

    .icon-cam
      content: url('../assets/images/video-camera.svg')
      position: relative;
      top: 12px;
      left: 0px;

    .brand-margin
      margin-top: calc(100% - 35vw)
      padding: 5%
      @media (max-width: lg)
        margin-top: 70rem;
        position: relative;

    .title-brands
      color: text-light
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 24px;

    .logo-brands
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;

    .h2-section
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 54px;
      text-align: left
      @media (max-width: lg)
          text-align: center
          margin: auto
          padding: 2%

    .p-section
        width: 70%;
        text-align: justify;
        @media (max-width: lg)
          text-align: center
          margin: auto
          width: 90%

    img.img-feat
        width: 80px;
        height: 80px;
        margin-right: 24px

        @media (max-width: lg)
          margin-top 24px
          margin-bottom 16px
          margin-right: 0px

    .title-feat-wrapper
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        cursor: pointer
        margin-bottom 24px
        align-items center

        @media (max-width: lg)
          flex-direction column !important
          margin: 0 auto 24px auto;

        & h3
          color: primary
          font-weight: bold
          text-align left
          nowrap()

          @media (max-width: lg)
            text-align center
            white-space normal

    .btn-store
      cursor pointer

    .container-center
        width: 100vw;
        padding: 1.5%

    .plano-title.section-purple
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;

    .media-storie
        color: rgb(255, 255, 255);
        color: #fff;
        text-align: left
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 96px;
        padding-right 32px
        @media (max-width: lg)
          font-size: 45px

    .rect-absolute
        position: absolute;
        top: auto;
        right: 0;
        height: 50rem;
        content: url('../assets/images/faq-screens.svg')
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: lg)
          width: 100%;
          height: auto;
          position relative

    #faq1, #faq2, #faq3, #faq4
      display: none
      opacity: 1;
      animation-name: FadeIn;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.2s;

      @keyframes FadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
    .acc-style
      cursor: pointer !important
      @media (max-width: lg)
        text-align: center

    .span-plan
        color: rgb(41, 51, 64);
        font-weight: 100;
        font-size: 18px;

    .feat
        position: absolute;
        /* left: 8%; */
        padding-top: 1%;

    .feat-plans
        padding-left: 10px;
        padding-right: 10px;

    .background-r {
      gold-background();

      .forms {
        display: flex;
        justify-content: space-between;

        @media (max-width: md) {
          flex-direction: column;
        }

        & > div {
          border-radius: 8px;
          background-color: content-base;
          padding: 4%;

          @media (max-width: md) {
            width: 95% !important;
            margin: 24px auto;
          }

          @media (max-width: sm) {
            width: 100% !important;
            margin: 16px auto;
          }
        }

        h1 {
          color: color-theme(true);
          margin-bottom: 20px;
          font-size: 2.1em;
        }

        button {
          background-color: primary-variant;
          border-radius: 8px;
          border: none;
          color: color-theme();
          width: 50%;
          min-width: 200px;
          padding: 10px 16px;
          margin-top: 12px;
        }

        .register {
          width: 55%;
        }

        .login {
          display: flex;
          align-items: center;
          width: 35%;
        }
      }
    }

    .form-group {
      position: relative;

      .input-eye {
        padding-right: 2.5rem;
      }

      .eye {
        position: absolute;
        right: 6px;
        bottom: 2px;
        font-size: 32px;
        color: primary;
        cursor: pointer;
        no-select();
      }
    }
</style>

<script>
import Topbar from '@/components/Topbar'
import Footer from '@/components/Footer'
import { api } from '@/services'
import { toast } from '@/utils'
import { Carousel, Slide } from 'vue-carousel'
export default {
  name: 'Sobre',
  metaInfo: {
    htmlAttrs: {
      lang: 'pt-BR'
    },
    title: 'Vulpee',
    meta: [
      {
        name: 'twitter:card',
        content: 'summary'
      },
      {
        name: 'twitter:title',
        content: 'Vulpee é seu app para entregas'
      },
      {
        name: 'description',
        content:
          'Vem com a Vulpee agilizar suas entregas! Conte com um app rápido e intuitivo para solicitar um entregador. Conte com preços justos e transparentes.'
      },
      { property: 'og:type', content: 'article' },
      {
        property: 'og:title',
        content: 'Vulpee é seu app para entregas'
      },
      {
        property: 'og:url',
        content:
          'https://vulpee.com/sobre'
      },
      {
        property: 'og:description',
        content:
          'Vem com a Vulpee agilizar suas entregas! Conte com um app rápido e intuitivo para solicitar um entregador. Conte com preços justos e transparentes.'
      },
      /* {
        property: "og:image",
        content: "https://shinier.com.br/img/mockup.bcc0b288.png",
      },
      { property: "og:image:width", content: "200" },
      { property: "og:image:height", content: "200" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/mockup.bcc0b288.png",
      }, */
      {
        name: 'twitter:description',
        content:
          'https://vulpee.com/sobre'
      },
      { property: 'og:site_name', content: 'Vulpee' }
    ]
  },
  components: { Topbar, Footer, Carousel, Slide },
  data () {
    return {
      windowSize: window.innerWidth,
      modalCadastro: false,
      modalSuccess: false,
      fieldsSender: [
        { key: 'name', label: 'Nome', placeholder: 'Nome Completo', type: 'text', col: 6, required: ' *', isRequired: true },
        {
          key: 'cpf',
          mask: ['###.###.###-##', '##.###.###/####-##'],
          label: 'Documento',
          type: 'text',
          placeholder: 'CPF',
          col: 6,
          required: ' *',
          isRequired: true
        },
        { key: 'email', label: 'Email', placeholder: 'Email', type: 'email', col: 6, required: ' *', isRequired: true },
        {
          key: 'telephone',
          mask: '(##) #####-####',
          label: 'Telefone',
          placeholder: 'Telefone',
          type: 'text',
          col: 6,
          required: ' *',
          isRequired: true
        },
        { key: 'password', label: 'Senha', type: 'password', placeholder: 'Nova senha', col: 6, required: ' *', isRequired: true },
        { key: 'password_confirmation', label: 'Confirmar senha', placeholder: 'Confirmar senha', type: 'password', col: 6, required: ' *', isRequired: true }
      ],
      fieldsDeliveries: [
        { key: 'nickname', label: 'Indicação', placeholder: 'Usuário de quem te indicou', type: 'text', col: 6, required: '', isRequired: false },
        { key: 'name', label: 'Apelido', placeholder: 'Esse apelido você irá passar nas indicações', type: 'text', col: 6, required: ' *', isRequired: true },
        {
          key: 'cpf',
          label: 'CNH',
          mask: '##########',
          type: 'text',
          placeholder: 'CNH',
          col: 6,
          required: ' *',
          isRequired: true
        },
        { key: 'email', label: 'Email', placeholder: 'Email', type: 'email', col: 6, required: ' *', isRequired: true },
        {
          key: 'telephone',
          mask: '(##) #####-####',
          label: 'Telefone',
          type: 'text',
          placeholder: 'Telefone',
          col: 6,
          required: ' *',
          isRequired: true
        },
        { key: 'password', label: 'senha', type: 'password', placeholder: 'Nova senha', col: 6, required: ' *', isRequired: true },
        { key: 'password_confirmation', label: 'Confirmar senha', placeholder: 'Confirmar senha', type: 'password', col: 6, required: ' *', isRequired: true }
      ],
      formSender: {},
      formDeliveries: {},
      formSwitch: true,
      eye: true,
      loading: false
    }
  },
  computed: {
    cardFeatures () {
      return [
        {
          img: require('../assets/images/sppedometer.svg'),
          title: 'Gaste menos com entregas',
          text: 'Não gaste uma fortuna com frota própria. Deixe suas entregas com a gente e pague apenas pelo que usar.'
        },
        {
          img: require('../assets/images/bolt.svg'),
          title: 'Entregas do seu jeito',
          text: 'Estamos sempre prontos para conectar seu negócio a motoristas parceiros, auditados e confiavéis.'
        },
        {
          img: require('../assets/images/wheelchair.svg'),
          title: 'Deixe seus clientes felizes',
          text: 'Entregue os pedidos no mesmo dia e ofereça uma experiência de compra única aos seus consumidores!'
        }
      ]
    },
    cardFeaturePosso () {
      return [
        {
          img: require('../assets/images/message-plane.svg'),
          title: 'Sem taxas',
          text: 'Receba pelo km rodado total. Calculado pela tabela pré-fixa no app.'
        },
        {
          img: require('../assets/images/phone.svg'),
          title: 'Preço direto no app',
          text: ' Saiba quanto vai receber assim que chegar ao local de coleta!'
        }
      ]
    },
    cardFeaturePreciso () {
      return [
        {
          img: require('../assets/images/security.svg'),
          title: 'Segurança',
          text: 'Plataforma 100% conformidade com a LGPD.'
        },
        {
          img: require('../assets/images/floater.svg'),
          title: 'Disponibilidade',
          text: ' Encontramos o entregador disponível mais próximo de você.'
        }
      ]
    },
    cardPlans () {
      return [
        {
          img: require('../assets/images/balloon.svg'),
          title: 'R$4800',
          text: 'POR MÊS'
        },
        {
          img: require('../assets/images/balloon (1).svg'),
          title: '600 KM',
          text: 'POR MÊS'
        }
      ]
    }
  },
  methods: {
    toLogin (plan) {
      this.$router.push({ name: 'Login', query: { plan } })
    },
    openAccordion (id, display) {
      var id_ = document.getElementById(id)
      display = !id_.style.display || (id_.style.display === 'none')
      if (display) {
        id_.style.display = 'flex'
      } else {
        id_.style.display = 'none'
      }
    },
    openModalCadastro () {
      this.modalCadastro = true
    },
    switchToSenders () {
      this.formSwitch = false
    },
    switchToDeliveries () {
      this.formSwitch = true
    },
    // a api pede um token para os cadastros, essa função gera ele
    // (** não é o token de auth **, e isso é provisório).
    makeid (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    submitRegister () {
      if (!this.$refs.formRegister.checkValidity()) {
        this.$emit('invalid', 'Preencha os campos corretamente')
        return
      }

      const f = { ...(!this.formSwitch ? this.formSender : this.formDeliveries), token: this.makeid(5) }

      f.group_key = !this.formSwitch ? 'Sender' : 'Deliveries'

      // Add password confirmation
      // f = { ...f, password_confirmation: f.password }

      this.loading = true
      // Submit
      api.post('register', f).then(
        (response) => {
          console.log(response)
          this.modalCadastro = false
          this.modalSuccess = true
          this.formSender = {}
          this.formDeliveries = {}
          this.loading = false
        },
        (error) => {
          this.loading = false
          toast(this, 'danger', 'Registro inválido', error.message)
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
